import {QbElement} from "./qb-element.js"

class QbCode extends QbElement {
    static observedAttributes = ["size", "value", "name", "disabled", "pattern"]
    static shadow = {mode: 'open', sheets: ["qb-elements"]}

    get name() {
        return this.#inputNode.name;
    }

    set name(value) {
        this.#inputNode.name = value;
    }

    get #inputNode() {
        if(!this.firstElementChild?.matches('input[type="hidden"]')) this.innerHTML = '<input type="hidden" name="code"/>';
        return this.firstElementChild;
    }

    $name(value) {
        this.#inputNode.name = value;
    }

    #pattern
    $pattern(value) {
        [...this.contentRoot.children].forEach(n => n.pattern = value);
        this.#pattern = value;
    }


    get value() { return this.#inputNode.value }
    set value(value) {
        debugger;
        this.#inputNode.value = value;
    }

    set disabled(value) {
        [...this.children].forEach(n=>n.disabled = value);
        this.#inputNode.disabled = value;
    }

    focus() {
        if(this.matches(':focus-within')) return;
        this.contentRoot.firstElementChild.focus();
    }

    $size(value) {
        [...this.contentRoot.children].forEach(n => n.remove());
        for(let i = 0; i < value; i++) this.contentRoot.append(
            cT('input type="text" autocomplete="off" required="true" maxLength="1" size="1"', {
                pattern: this.#pattern,
                style: 'text-align:center',
                events: [this, "input", "beforeinput"]
            })
        );
    }


    handleEvent_beforeinput(evt) {
        if(evt.inputType === 'insertFromPaste') {
            evt.preventDefault();
            const m = evt.data.match(/[A-Za-z0-9]+$/);
            if(!m) return;
            const code = m[0];
            [...this.contentRoot.children].forEach((n,i) => n.value=code[i] || "");
            this.contentRoot.lastElementChild.focus();
            this.#inputNode.value = [...this.contentRoot.children].map(n=>n.value).join("");
            return;
        }
        if(evt.inputType === 'deleteContentBackward' && !evt.currentTarget.value) {
            evt.currentTarget.previousElementSibling?.focus();
        }

        evt.currentTarget.value = '';
    }

    handleEvent_input(evt) {
        this.#inputNode.value = [...this.contentRoot.children].map(n=>n.value).join("");
        if(evt.inputType === 'deleteContentBackward') return;
        let next = evt.currentTarget.nextElementSibling;
        if(next) next.focus();
        else evt.currentTarget.blur();
    }
}

QbCode.register;